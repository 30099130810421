




































import { computed, defineComponent, ref } from "@vue/composition-api";
import router from "@/router";
import { useAuth } from "@/api_connectors/auth";
import FailedRequest from "@/components/Toast/FailedRequest.vue";
import SuccessRequest from "@/components/Toast/SuccessRequest.vue";
import { useToast } from "@/composables/useToast";
import FormInvalidToast from "@/components/Toast/FormInvalidToast.vue";

export default defineComponent({
    name: "ResetPassword",
    components: { FormInvalidToast, SuccessRequest, FailedRequest },
    setup() {
        const newPW = ref('')
        const pwConfirm = ref('')
        const result = ref(false)
        const sentRequest = ref(false)
        const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', 'g')

        const { resetPW } = useAuth()
        const { showToast } = useToast()

        const resetWithPW = async (): Promise<void> => {
            if (newPW.value === '') return showToast('form-invalid-toast');
            if (!passwordValid.value || !passwordSecure.value) return showToast('form-invalid-toast');

            const token = router.currentRoute.params.token

            result.value = await resetPW({ token: token, password: newPW.value })

            sentRequest.value = true;

            setTimeout(() => {
                router.push('/')
            }, 2000)
        }

        const passwordValid = computed(() => {
            if (newPW.value === '' || pwConfirm.value === '') return null

            return newPW.value === pwConfirm.value
        })

        const passwordSecure = computed(() => {
            if (newPW.value === '' && !sentRequest.value) return null;

            return passwordRegex.test(newPW.value);
        })

        return {
            newPW,
            pwConfirm,
            result,
            sentRequest,
            passwordValid,
            passwordSecure,
            resetWithPW
        }
    }
})
